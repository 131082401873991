export const validPromos = [
  "BEST_50OFF",
  "FOND50",
  "SWEAT50",
  "MAVELY50",
  "UNITED50",
  "FITNESS50",
  "FORBES50",
  "MAIL08",
  "TMT20",
]

export const DEFAULT_BANNER = "default"
